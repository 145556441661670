<main class="container-fluid">
    <div class="row"> 
  
  <nav id="tmSidebar" class="tm-bg-black-transparent tm-sidebar">
    <button class="navbar-toggler" type="button" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>
    <div class="tm-sidebar-sticky">
      <div class="tm-brand-box">
     
     
      </div>
  
      <ul id="tmMainNav" class="nav flex-column text-uppercase text-right tm-main-nav">
        <li class="nav-item">
          <a href="#intro" class="nav-link active">
            <span class="d-inline-block mr-3">Proposal</span>
            <span class="d-inline-block tm-white-rect"></span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#about" class="nav-link">
            <span class="d-inline-block mr-3">Documents</span>
            <span class="d-inline-block tm-white-rect"></span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#userawareness" class="nav-link">
            <span class="d-inline-block mr-3">User Awareness</span>
            <span class="d-inline-block tm-white-rect"></span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#components" class="nav-link">
            <span class="d-inline-block mr-3">Components</span>
            <span class="d-inline-block tm-white-rect"></span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#technology" class="nav-link">
            <span class="d-inline-block mr-3">Technology</span>
            <span class="d-inline-block tm-white-rect"></span>
          </a>
        </li>
        <li class="nav-item">
            <a href="#skills" class="nav-link">
              <span class="d-inline-block mr-3">Skills</span>
              <span class="d-inline-block tm-white-rect"></span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#samples" class="nav-link">
              <span class="d-inline-block mr-3">Work Samples</span>
              <span class="d-inline-block tm-white-rect"></span>
            </a>
          </li>
      </ul>
      <!-- 
      <ul class="nav flex-row tm-social-links">
        <li class="nav-item">
          <a href="https://facebook.com" class="nav-link tm-social-link">
            <i class="fab fa-facebook-f"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://twitter.com" class="nav-link tm-social-link">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://dribbble.com" class="nav-link tm-social-link">
            <i class="fab fa-dribbble"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://linkedin.com" class="nav-link tm-social-link">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    -->
      <footer class="text-center text-white small">
        <p class="mb--0 mb-2"></p>
        <p class="mb-0">
     
        </p>
      </footer>
    </div>
  </nav>
  
  
  
  
  <main role="main" class="ml-sm-auto col-12">
    <div
                class="parallax-window"
                data-parallax="scroll"
                data-image-src="../../assets/img/dream-pulse-header.jpg">

        <div class="tm-section-wrap">
            <section id="intro" >
                <div style="color: white; background-color: #0094ca; margin-left: -15px; margin-right: -15px;"  class="jumbotron jumbotron-fluid">
                    <div class="container">
                            <div style="font-weight: bold; margin-left: 120px;" >
                         

                                <h1 class="display-4">Proposal</h1>
                                <p class="lead">Portal Hub and Central Communication Solution for Moderation Teams</p>
                            </div>
                        </div>
                    </div>
                      <br />
                      <br />
                    

                 
                        <div style="margin-left: 200px; padding-right: 150px; color: grey;">
                        
    

                              <span style="font-size: 22px;">  <h2> Develop a single location for all communications and data presentation for all projects.</h2> </span>
                                
                                <br />
                                <h4>Simplify Current Options</h4>
                                <p>Take our collection of multiple documents currently residing on different platforms and in different locations and make them all accessible from one location. </p>
                                

                                <h4>Create Consistency with Console styles and Function</h4>
                                <p>Build an interface that employees are already familiar with and know how to use without additional training.</p>

                                <h4>Error Proof our Management to Employee Communications</h4>
                                <p>Rather than send team-wide communications to employees via email or in Slack make all communications fool proof with a central location for presentation.</p>

                                

                        </div>
                           
                  </section>
              </div>            
            </div>
  







            <div class="tm-section-wrap bg-white">
                <section id="about" class="row tm-section">
                  <div class="col-xl-12">
                    <div style="font-size: 14px; height: 800px;">
  
  
                  
  
                          <div style="color: white; background-color: #ca8b37; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                              <div class="container">
                                  <div style="font-weight: bold; margin-left: 120px;" >
                                      <div style="font-weight: bold; margin-left: 0px;" >
                           
  
                                          <h1 class="display-4">Documents</h1>
                                          <p class="lead">Single page interface presenting all pertinent day to day docs and data at a glance.</p>
                                      </div>
                     </div>
                     </div>
                     </div> <br />


                     <h5 class="tm-color-primary tm-section-title mb-4 ml-3">Documents and Data</h5>
                     <p>Create and manage our documents in one convenient space. 
                     </p>
                     <p>
                        Create formatted documents on the fly in real time. Once a document has been
                        created it stays in an archive list of created items.  The formatted document that was created could be a note, a full formatted webpage with images uploaded etc. These docs can be used  
                         to communicate with the team globally and make sure that no communications are ever missed. 
 
                         Once created a group communication document pops up for any employee who logs into the system. The user must agree to having seen and read 
                         the document before exiting out of the doc and into the regular interface. 
                 
                         A list is viewable of who has and has not seen the communication. Communications are archived and can be re-used or altered as templates.
                     </p>
 
                     <p>Notes can also be sent individually and privately to employees using the same alert system described above.</p>
 
                     <p>If a user is logged in they will be alerted by a sound that there is a communication waiting for them to read so this can be used in real time as well.</p>
 
                     
                     <p>
                         Google Docs can be integrated into the application allowing managers to create, view and update documents from within a single space. 
                         <a href="https://developers.google.com/docs/api/how-tos/overview">https://developers.google.com/docs/api/how-tos/overview</a>
                     </p>
 



                    </div>
                </div>
              </section>
            </div>
  



            <div class="tm-section-wrap bg-white">
                <section id="userawareness" class="row tm-section">
                  <div class="col-xl-12">
                    <div style="font-size: 14px; height: 800px;">
  
  
                  
  
                          <div style="color: white; background-color: #6f85b4; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                              <div class="container">
                                  <div style="font-weight: bold; margin-left: 120px;" >
                                      <div style="font-weight: bold; margin-left: 0px;" >
                           
  
                                          <h1 class="display-4">User Awareness</h1>
                                          <p class="lead">Know who is online, what they are working on and when. </p>
                                      </div>
                     </div>
                     </div>
                     </div> <br />


                     <h5 class="tm-color-primary tm-section-title mb-4 ml-3">User Awareness Tools</h5>
                     <p>See icons/images of people who are currently logged into the application. Always know who is online. 
                     </p>
                     <p>
                         Use Google Docs schedule to track shift changes. With this you can always see at a glance who is responsible for which projects, when they are done 
                         and who is replacing them and at what time. Optionally, Alerts can be shared when shifts change. 
                 </p>
                 <p>
                     Use the Slack API to see the public workflow we already use now and join in if needed. 
                 </p>
                     <p>See an archive of communications you have sent to employees. </p>
 
                     <p>See who has read your communications.</p>
                  
 



                    </div>
                </div>
              </section>
            </div>
  







            <div class="tm-section-wrap bg-white">
                <section id="components" class="row tm-section">
                  <div class="col-xl-12">
                    <div style="font-size: 14px; height: 800px;">
  
  
                  
  
                          <div style="color: white; background-color: #70c084; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                              <div class="container">
                                  <div style="font-weight: bold; margin-left: 120px;" >
                                      <div style="font-weight: bold; margin-left: 0px;" >
                           
  
                                          <h1 class="display-4">Components</h1>
                                          <p class="lead">Communicate, Create, Connect. </p>
                                      </div>
                     </div>
                     </div>
                     </div> <br />


                     <h5 class="tm-color-primary tm-section-title mb-4 ml-3">Components or Tools Available</h5>
                     <p>Each tool will be tucked away in an attractve interface that is easily navigated without any learning curve or training required. Menus will bring tools to full size.
                     </p>
                     

                    <ul>
                        <li>User Center</li>
                        <ul>
                            <li>See who is online</li>
                            <li>See schedule info per user</li>
                            
                        </ul>
                        <li>Message Center</li>
                        <ul>
                            <li>Send group messages</li>
                            <li>Send individual messages</li>
                            <li>See who has seen your communications</li>
                            <li>Access elements of Slack</li>

                        </ul>
                        <li>Document Center</li>
                        <ul>
                            <li>View and Edit Google Docs</li>
                            <li>Create New Google Docs</li>
                            <li>Convert docs to PDF for download</li>
                        </ul>
                        <li>Project Center</li>
                        <ul>
                            <li>See all project information at a glance</li>
                            <li>View SLAs, banning docs, guidelines etc. for each project</li>
                            <li>Edit and Update docs from within the Project Center</li>
                        </ul>
                        <li>Training Center for viewing training materials</li>
                        <ul>
                            <li>All videos available including past meetings</li>
                            <li>All notes for all projects available</li>
                            <li>All training material available</li>
                            
                        </ul>
                    </ul>
                    <br /><br /><br />

 



                    </div>
                </div>
              </section>
            </div>
  
















            <div class="tm-section-wrap bg-white">
                <section id="technology" class="row tm-section">
                  <div class="col-xl-12">
                    <div style="font-size: 14px; height: 800px;">
  
  
                  
  
                          <div style="color: white; background-color: #65c4bf; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                              <div class="container">
                                  <div style="font-weight: bold; margin-left: 120px;" >
                                      <div style="font-weight: bold; margin-left: 0px;" >
                           
  
                                          <h1 class="display-4">Technology</h1>
                                          <p class="lead">Languages, Servers, Databases. </p>
                                      </div>
                     </div>
                     </div>
                     </div> <br />


                     <h5 class="tm-color-primary tm-section-title mb-4 ml-3">Documents and Data</h5>
              
                     <table class="table table-striped">
                        <thead>
                          <tr>
                           
                            <th scope="col">Element</th>
                            <th scope="col">Location</th>
                            <th scope="col">Language</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Framework</td>
                                <td>Frontend Server</td>
                                <td>HTML/Typescript/Javascript/Angular 8/9</td>
                            </tr>
                            <tr>
                                <td>API</td>
                                <td>Backend Server</td>
                                <td>PHP: no framework</td>
                            </tr>
                            <tr>
                                <td>Database</td>
                                <td>Backend Server/AWS</td>
                                <td>MySQL</td>
                            </tr>
                            <tr>
                        
                                <td>Version Control</td>
                                <td>Github</td>
                                <td>Managed through a local CLI repository</td>
                            </tr>
                            <tr>
                                <td>Local Dev</td>
                                <td>Virtual Machine running Ubuntu on Vagrant </td>
                                <td>Various</td>
                            </tr>
                            <tr>
                                <td>Charting</td>
                                <td>Google Spreadsheets Integration</td>
                                <td>Javascript</td>
                            </tr>
                            <tr> 
                                <td>Third Party APIs</td>
                                <td>Google Docs API / Slack API</td>
                                <td>Javascript</td>
                            </tr>
                        </tbody>
                      </table>
 



                    </div>
                </div>
              </section>
            </div>


            <div class="tm-section-wrap bg-white">
                <section id="skills" class="row tm-section">
                  <div class="col-xl-12">
                    <div style="font-size: 14px; height: 800px;">
  
  
                  
  
                          <div style="color: white; background-color: #c97cb6; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                              <div class="container">
                                  <div style="font-weight: bold; margin-left: 120px;" >
                                      <div style="font-weight: bold; margin-left: 0px;" >
                           
  
                                          <h1 class="display-4">Skills</h1>
                                          <p class="lead">My experience and skills. </p>
                                      </div>
                     </div>
                     </div>
                     </div> <br />


                     <h5 class="tm-color-primary tm-section-title mb-4 ml-3">What I bring to the project</h5>

                     <p> I have many years of programming experience in multiple technologies and languages. I've built small projects on my own, a social project manager, a Rent to Own Point of Sale and contract management tool, Printing Press Quoter in PERL, Math formulas converted to Javascript for a Lottery calculator and others.  I've worked on large 
                         team driven enterprise projects used by Twitter, Coke, Sony, and many others. 
                     </p>

                     <p> Currently I primarily work in Angular 2 - 8 and PHP APIs secured by JWT tokens. I've worked on PHP applications in the past and have utilized Javascript libraries like JQuery and others.</p>
                     <p> In Angular I've implemented NPM Packages like bootstrap, Bulma, Material Design and many other addon packages.</p>

                     <p>I've created full stack applications and worked as part of a full stack team. I've designed databases for all my projects and also done the UX in all my projects.
                    </p>
 
        


                    </div>
                </div>
              </section>
            </div>






            <div class="tm-section-wrap bg-white">
            <section id="samples" class="row tm-section">
              <div class="col-xl-12">
                <div style="font-size: 14px; height: 800px;">


              

                      <div style="color: white; background-color: #8d6cca; margin-top: -75px; margin-left: -75px; margin-right: -75px;"  class="jumbotron jumbotron-fluid">
                          <div class="container">
                              <div style="font-weight: bold; margin-left: 120px;" >
                                  <div style="font-weight: bold; margin-left: 0px;" >
                       

                                      <h1 class="display-4">Past Work Samples</h1>
                                      <p class="lead">Work I've done previously. </p>
                                  </div>
                 </div>
                 </div>
                 </div> <br />


                 <h5 class="tm-color-primary tm-section-title mb-4 ml-3">Some Interface Examples</h5>

                 <div class=" ">
                    <div class="">
                      <div class="tm-gallery-wrap">
                          
                          <div class="tm-gallery">
                            <div class="tm-gallery-item">                        
                              <figure class="effect-bubba">
                                <img src="../../assets/img/portfolio-MK-1.jpg" width="300"alt="Our Work Image" class="img-fluid">
                                <figcaption>
                                  <h2> <span>Social Network</span></h2>
                                  <p>Group Networking</p>
                               
                                </figcaption>
                              </figure>
                            </div>
                            <div class="tm-gallery-item">
                              <figure class="effect-bubba">
                                <img src="../../assets/img/portfolio-MK-2.jpg" width="300" alt="Our Work Image" class="img-fluid">
                                <figcaption>
                                  <h2> <span>Work Tools</span></h2>
                                  <p>Collaboration Tools</p>
                                 
                                </figcaption>
                              </figure>
                            </div>
                            <div class="tm-gallery-item">
                              <figure class="effect-bubba">
                                <img src="../../assets/img/portfolio-MK-3.jpg" width="300" alt="Our Work Image" class="img-fluid">
                                <figcaption>
                                  <h2> <span>Social Projects</span></h2>
                                  <p>Working together on projects</p>
                                 
                                </figcaption>
                              </figure>
                            </div>
                            <div class="tm-gallery-item">
                                <figure class="effect-bubba">
                                  <img src="../../assets/img/main-slider3Social3.jpg" width="445" alt="Our Work Image" class="img-fluid">
                                  <figcaption>
                                    <h2> <span>Social Projects</span></h2>
                                    <p>Working with chart data</p>
                                   
                                  </figcaption>
                                </figure>
                              </div>
                              <div class="tm-gallery-item">
                                <figure class="effect-bubba">
                                  <img src="../../assets/img/main-slider3Social1.jpg" width="445" alt="Our Work Image" class="img-fluid">
                                  <figcaption>
                                    <h2> <span>Remote Work</span></h2>
                                    <p>Communication Wall   </p>
                                    
                                  </figcaption>
                                </figure>
                              </div>
                         <!--       <div class="tm-gallery-item">
                               <figure class="effect-bubba">
                                  <img src="../../assets/img/Screeniphone.png" width="300" alt="Our Work Image" class="img-fluid">
                                  <figcaption>
                                    <h2> <span>Social Projects</span></h2>
                                    <p>Working together on projects</p>
                                    <a href="../../assets/img/portfolio-MK-3.jpg">View more</a>
                                  </figcaption>
                                </figure>
                              </div>
                            <img src="../../assets/img/Screeniphone.png" width="200px">
                            <img src="../../assets/img/main-slider3Social3.jpg" width="550px">
                            <img src="../../assets/img/main-slider3Social1.jpg" width="200px">
                            -->

                        </div>
                    </div>                        
                  </div>                  
                </div> 


              
                </div>
            </div>
          </section>
        </div>












            
        </main>
    </div>
    </main>